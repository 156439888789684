export const SPECIAL_DENOMS_NODE_ID = -42

export interface TreeNode {
  id: number
  label: string
  parent_id: number // eslint-disable-line camelcase
  children: TreeNode[]
}

export interface Denom {
  id: number
  value: string
}

export interface Product {
  id: number
  name: string
  image_url: string // eslint-disable-line camelcase
  ean: null | string
}

export interface State {
  isLoading: {
    tree: Boolean
    errorNode: Boolean
  }
  isLoaded: {
    tree: Boolean
    errorNode: Boolean
  }
  error: {
    tree: any
    errorNode: any
  }

  tree: null | TreeNode
  errorNode: null | TreeNode

  includeFinalDenoms: Boolean

  selectedOriginNode: null | TreeNode
  selectedOriginNodeDenoms: Denom[]
  isFetchingSelectedOriginNodeDenoms: Boolean
  errorForSelectedOriginNodeDenoms: any

  filterOnDenoms: null | string
  inverseFilterOnDenoms: Boolean
  filterOnProducts: null | string

  denomsToMove: Denom[]
  isMovingDenoms: Boolean
  isValidatingDenoms: Boolean
  isInvalidatingDenoms: Boolean

  selectedTargetNode: null | TreeNode
  selectedTargetNodeProducts: Product[]
  isFetchingSelectedTargetNodeProducts: Boolean
  errorForSelectedTargetNodeProducts: any
}

export default () => ({
  isLoading: {
    tree: false,
    errorNode: false
  },
  isLoaded: {
    tree: false,
    errorNode: false
  },
  error: {
    tree: null,
    errorNode: null
  },

  tree: null,
  errorNode: null,

  includeFinalDenoms: false,

  selectedOriginNode: null,
  selectedOriginNodeDenoms: [],
  isFetchingSelectedOriginNodeDenoms: false,
  errorForSelectedOriginNodeDenoms: null,

  filterOnDenoms: null,
  inverseFilterOnDenoms: false,
  filterOnProducts: null,

  denomsToMove: [],
  isMovingDenoms: false,
  isValidatingDenoms: false,
  isInvalidatingDenoms: false,

  selectedTargetNode: null,
  selectedTargetNodeProducts: [],
  isFetchingSelectedTargetNodeProducts: false,
  errorForSelectedTargetNodeProducts: null
})
