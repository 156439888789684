import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31f0396b = () => interopDefault(import('../pages/analytics_jobs/index.vue' /* webpackChunkName: "pages/analytics_jobs/index" */))
const _5445cd44 = () => interopDefault(import('../pages/animation/index.vue' /* webpackChunkName: "pages/animation/index" */))
const _2d9e682c = () => interopDefault(import('../pages/create-program.vue' /* webpackChunkName: "pages/create-program" */))
const _2500a0e9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67880331 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _a96340c6 = () => interopDefault(import('../pages/metrics/index.vue' /* webpackChunkName: "pages/metrics/index" */))
const _5b4c09aa = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _2b0c07a8 = () => interopDefault(import('../pages/select-program.vue' /* webpackChunkName: "pages/select-program" */))
const _3128cc52 = () => interopDefault(import('../pages/sources/index.vue' /* webpackChunkName: "pages/sources/index" */))
const _e5e0233c = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _2f088ee6 = () => interopDefault(import('../pages/administrators/managers.vue' /* webpackChunkName: "pages/administrators/managers" */))
const _3f1bc76f = () => interopDefault(import('../pages/animation/campaigns.vue' /* webpackChunkName: "pages/animation/campaigns" */))
const _1b9a54f3 = () => interopDefault(import('../pages/animation/messages/index.vue' /* webpackChunkName: "pages/animation/messages/index" */))
const _28ab5304 = () => interopDefault(import('../pages/animation/offers/index.vue' /* webpackChunkName: "pages/animation/offers/index" */))
const _5d73a28a = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _3e58a0fb = () => interopDefault(import('../pages/auth/resetting_password.vue' /* webpackChunkName: "pages/auth/resetting_password" */))
const _6721b647 = () => interopDefault(import('../pages/error/403.vue' /* webpackChunkName: "pages/error/403" */))
const _3a613460 = () => interopDefault(import('../pages/metrics/canals/index.vue' /* webpackChunkName: "pages/metrics/canals/index" */))
const _658d0ad2 = () => interopDefault(import('../pages/metrics/program/index.vue' /* webpackChunkName: "pages/metrics/program/index" */))
const _11ea575e = () => interopDefault(import('../pages/programs/contents.vue' /* webpackChunkName: "pages/programs/contents" */))
const _5c8dfc3d = () => interopDefault(import('../pages/programs/management/index.vue' /* webpackChunkName: "pages/programs/management/index" */))
const _f254fe60 = () => interopDefault(import('../pages/programs/rules.vue' /* webpackChunkName: "pages/programs/rules" */))
const _5976d270 = () => interopDefault(import('../pages/sources/bars.vue' /* webpackChunkName: "pages/sources/bars" */))
const _2d353bc3 = () => interopDefault(import('../pages/sources/points-of-sale.vue' /* webpackChunkName: "pages/sources/points-of-sale" */))
const _263a4697 = () => interopDefault(import('../pages/sources/products/index.vue' /* webpackChunkName: "pages/sources/products/index" */))
const _809704b2 = () => interopDefault(import('../pages/sources/products-collection.vue' /* webpackChunkName: "pages/sources/products-collection" */))
const _296f5218 = () => interopDefault(import('../pages/users/fraud.vue' /* webpackChunkName: "pages/users/fraud" */))
const _d4469eb4 = () => interopDefault(import('../pages/users/management/index.vue' /* webpackChunkName: "pages/users/management/index" */))
const _bde18980 = () => interopDefault(import('../pages/users/segments.vue' /* webpackChunkName: "pages/users/segments" */))
const _0f7bd478 = () => interopDefault(import('../pages/users/transfers.vue' /* webpackChunkName: "pages/users/transfers" */))
const _7d6cf606 = () => interopDefault(import('../pages/animation/messages/emails.vue' /* webpackChunkName: "pages/animation/messages/emails" */))
const _5f43fa8a = () => interopDefault(import('../pages/animation/messages/messages.vue' /* webpackChunkName: "pages/animation/messages/messages" */))
const _71b1df4b = () => interopDefault(import('../pages/animation/offers/bonus.vue' /* webpackChunkName: "pages/animation/offers/bonus" */))
const _374c9bec = () => interopDefault(import('../pages/animation/offers/grouped.vue' /* webpackChunkName: "pages/animation/offers/grouped" */))
const _0c893708 = () => interopDefault(import('../pages/animation/offers/refund.vue' /* webpackChunkName: "pages/animation/offers/refund" */))
const _0bbb564e = () => interopDefault(import('../pages/metrics/canals/general.js' /* webpackChunkName: "pages/metrics/canals/general" */))
const _74ff7e30 = () => interopDefault(import('../pages/metrics/canals/onboarding_funnel.vue' /* webpackChunkName: "pages/metrics/canals/onboarding_funnel" */))
const _a9f40396 = () => interopDefault(import('../pages/metrics/canals/points_of_sale.vue' /* webpackChunkName: "pages/metrics/canals/points_of_sale" */))
const _34714ca7 = () => interopDefault(import('../pages/metrics/program/general.js' /* webpackChunkName: "pages/metrics/program/general" */))
const _018e4b1d = () => interopDefault(import('../pages/metrics/program/generosity.vue' /* webpackChunkName: "pages/metrics/program/generosity" */))
const _2a995447 = () => interopDefault(import('../pages/metrics/program/individual_behaviour.vue' /* webpackChunkName: "pages/metrics/program/individual_behaviour" */))
const _de9503e8 = () => interopDefault(import('../pages/metrics/program/sales.vue' /* webpackChunkName: "pages/metrics/program/sales" */))
const _78593fbe = () => interopDefault(import('../pages/metrics/program/usage.vue' /* webpackChunkName: "pages/metrics/program/usage" */))
const _9430599a = () => interopDefault(import('../pages/sources/products/denoms.vue' /* webpackChunkName: "pages/sources/products/denoms" */))
const _540ce306 = () => interopDefault(import('../pages/sources/products/denoms-v2.vue' /* webpackChunkName: "pages/sources/products/denoms-v2" */))
const _0b054969 = () => interopDefault(import('../pages/sources/products/tree.vue' /* webpackChunkName: "pages/sources/products/tree" */))
const _a96f1e94 = () => interopDefault(import('../pages/programs/management/_programIdentifier/index.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/index" */))
const _8fdf2b08 = () => interopDefault(import('../pages/users/management/_userId/index.vue' /* webpackChunkName: "pages/users/management/_userId/index" */))
const _5eb709a9 = () => interopDefault(import('../pages/programs/management/_programIdentifier/applet.js' /* webpackChunkName: "pages/programs/management/_programIdentifier/applet" */))
const _29ee41e0 = () => interopDefault(import('../pages/programs/management/_programIdentifier/assets.js' /* webpackChunkName: "pages/programs/management/_programIdentifier/assets" */))
const _3256e4d0 = () => interopDefault(import('../pages/programs/management/_programIdentifier/brands.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/brands" */))
const _a4716d06 = () => interopDefault(import('../pages/programs/management/_programIdentifier/catalog.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/catalog" */))
const _517cc6c7 = () => interopDefault(import('../pages/programs/management/_programIdentifier/color.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/color" */))
const _3441c17a = () => interopDefault(import('../pages/programs/management/_programIdentifier/descriptions.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/descriptions" */))
const _9cbdb094 = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods" */))
const _085bfb4c = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/index.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/index" */))
const _38c95c80 = () => interopDefault(import('../pages/programs/management/_programIdentifier/points.js' /* webpackChunkName: "pages/programs/management/_programIdentifier/points" */))
const _742aed01 = () => interopDefault(import('../pages/programs/management/_programIdentifier/precisions.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/precisions" */))
const _3f5d6745 = () => interopDefault(import('../pages/programs/management/_programIdentifier/puzzle.js' /* webpackChunkName: "pages/programs/management/_programIdentifier/puzzle" */))
const _1753d7b4 = () => interopDefault(import('../pages/programs/management/_programIdentifier/renewal_strategy.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/renewal_strategy" */))
const _53dd7933 = () => interopDefault(import('../pages/programs/management/_programIdentifier/stamps.js' /* webpackChunkName: "pages/programs/management/_programIdentifier/stamps" */))
const _5c120444 = () => interopDefault(import('../pages/programs/management/_programIdentifier/visibility.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/visibility" */))
const _dddc49e4 = () => interopDefault(import('../pages/users/management/_userId/app/index.vue' /* webpackChunkName: "pages/users/management/_userId/app/index" */))
const _7ab55342 = () => interopDefault(import('../pages/users/management/_userId/declarations/index.vue' /* webpackChunkName: "pages/users/management/_userId/declarations/index" */))
const _4327c448 = () => interopDefault(import('../pages/users/management/_userId/profile.js' /* webpackChunkName: "pages/users/management/_userId/profile" */))
const _04f96507 = () => interopDefault(import('../pages/users/management/_userId/timeline.vue' /* webpackChunkName: "pages/users/management/_userId/timeline" */))
const _98b82dc2 = () => interopDefault(import('../pages/users/management/_userId/app/cards.vue' /* webpackChunkName: "pages/users/management/_userId/app/cards" */))
const _29fe9e6e = () => interopDefault(import('../pages/users/management/_userId/app/coupons.vue' /* webpackChunkName: "pages/users/management/_userId/app/coupons" */))
const _e875b740 = () => interopDefault(import('../pages/users/management/_userId/app/misc.vue' /* webpackChunkName: "pages/users/management/_userId/app/misc" */))
const _083fc411 = () => interopDefault(import('../pages/users/management/_userId/app/pot.vue' /* webpackChunkName: "pages/users/management/_userId/app/pot" */))
const _33845335 = () => interopDefault(import('../pages/users/management/_userId/app/timeline.vue' /* webpackChunkName: "pages/users/management/_userId/app/timeline" */))
const _3b1783e5 = () => interopDefault(import('../pages/users/management/_userId/bans/_banId.ts' /* webpackChunkName: "pages/users/management/_userId/bans/_banId" */))
const _1a7d658b = () => interopDefault(import('../pages/users/management/_userId/declarations/_declarationIdentifier.js' /* webpackChunkName: "pages/users/management/_userId/declarations/_declarationIdentifier" */))
const _36cb37a2 = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/acquisitions.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/acquisitions" */))
const _0a245d5e = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/explicit_mixity.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/explicit_mixity" */))
const _c327335a = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/glances.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/glances" */))
const _126b746a = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/in_app_operation.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/in_app_operation" */))
const _5c3d46ee = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/mixity.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/mixity" */))
const _9d069efa = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/purchases_by_region.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/purchases_by_region" */))
const _56ec150f = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/refunds.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/refunds" */))
const _4301d6ec = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/retailer_share.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/retailer_share" */))
const _6ad1f7ba = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/sales_details.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/sales_details" */))
const _c2d70350 = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/spendings_quantiles.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/spendings_quantiles" */))
const _739c427c = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/uplift_reports.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/uplift_reports" */))
const _45d89360 = () => interopDefault(import('../pages/programs/management/_programIdentifier/periods_new/_periodId/widget.vue' /* webpackChunkName: "pages/programs/management/_programIdentifier/periods_new/_periodId/widget" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics_jobs",
    component: _31f0396b,
    name: "analytics_jobs___fr"
  }, {
    path: "/animation",
    component: _5445cd44,
    name: "animation___fr"
  }, {
    path: "/create-program",
    component: _2d9e682c,
    name: "create-program___fr"
  }, {
    path: "/en",
    component: _2500a0e9,
    name: "index___en"
  }, {
    path: "/inspire",
    component: _67880331,
    name: "inspire___fr"
  }, {
    path: "/metrics",
    component: _a96340c6,
    name: "metrics___fr"
  }, {
    path: "/programs",
    component: _5b4c09aa,
    name: "programs___fr"
  }, {
    path: "/select-program",
    component: _2b0c07a8,
    name: "select-program___fr"
  }, {
    path: "/sources",
    component: _3128cc52,
    name: "sources___fr"
  }, {
    path: "/users",
    component: _e5e0233c,
    name: "users___fr"
  }, {
    path: "/administrators/managers",
    component: _2f088ee6,
    name: "administrators-managers___fr"
  }, {
    path: "/animation/campaigns",
    component: _3f1bc76f,
    name: "animation-campaigns___fr"
  }, {
    path: "/animation/messages",
    component: _1b9a54f3,
    name: "animation-messages___fr"
  }, {
    path: "/animation/offers",
    component: _28ab5304,
    name: "animation-offers___fr"
  }, {
    path: "/auth/login",
    component: _5d73a28a,
    name: "auth-login___fr"
  }, {
    path: "/auth/resetting_password",
    component: _3e58a0fb,
    name: "auth-resetting_password___fr"
  }, {
    path: "/en/analytics_jobs",
    component: _31f0396b,
    name: "analytics_jobs___en"
  }, {
    path: "/en/animation",
    component: _5445cd44,
    name: "animation___en"
  }, {
    path: "/en/create-program",
    component: _2d9e682c,
    name: "create-program___en"
  }, {
    path: "/en/inspire",
    component: _67880331,
    name: "inspire___en"
  }, {
    path: "/en/metrics",
    component: _a96340c6,
    name: "metrics___en"
  }, {
    path: "/en/programs",
    component: _5b4c09aa,
    name: "programs___en"
  }, {
    path: "/en/select-program",
    component: _2b0c07a8,
    name: "select-program___en"
  }, {
    path: "/en/sources",
    component: _3128cc52,
    name: "sources___en"
  }, {
    path: "/en/users",
    component: _e5e0233c,
    name: "users___en"
  }, {
    path: "/error/403",
    component: _6721b647,
    name: "error-403___fr"
  }, {
    path: "/metrics/canals",
    component: _3a613460,
    name: "metrics-canals___fr"
  }, {
    path: "/metrics/program",
    component: _658d0ad2,
    name: "metrics-program___fr"
  }, {
    path: "/programs/contents",
    component: _11ea575e,
    name: "programs-contents___fr"
  }, {
    path: "/programs/management",
    component: _5c8dfc3d,
    name: "programs-management___fr"
  }, {
    path: "/programs/rules",
    component: _f254fe60,
    name: "programs-rules___fr"
  }, {
    path: "/sources/bars",
    component: _5976d270,
    name: "sources-bars___fr"
  }, {
    path: "/sources/points-of-sale",
    component: _2d353bc3,
    name: "sources-points-of-sale___fr"
  }, {
    path: "/sources/products",
    component: _263a4697,
    name: "sources-products___fr"
  }, {
    path: "/sources/products-collection",
    component: _809704b2,
    name: "sources-products-collection___fr"
  }, {
    path: "/users/fraud",
    component: _296f5218,
    name: "users-fraud___fr"
  }, {
    path: "/users/management",
    component: _d4469eb4,
    name: "users-management___fr"
  }, {
    path: "/users/segments",
    component: _bde18980,
    name: "users-segments___fr"
  }, {
    path: "/users/transfers",
    component: _0f7bd478,
    name: "users-transfers___fr"
  }, {
    path: "/animation/messages/emails",
    component: _7d6cf606,
    name: "animation-messages-emails___fr"
  }, {
    path: "/animation/messages/messages",
    component: _5f43fa8a,
    name: "animation-messages-messages___fr"
  }, {
    path: "/animation/offers/bonus",
    component: _71b1df4b,
    name: "animation-offers-bonus___fr"
  }, {
    path: "/animation/offers/grouped",
    component: _374c9bec,
    name: "animation-offers-grouped___fr"
  }, {
    path: "/animation/offers/refund",
    component: _0c893708,
    name: "animation-offers-refund___fr"
  }, {
    path: "/en/administrators/managers",
    component: _2f088ee6,
    name: "administrators-managers___en"
  }, {
    path: "/en/animation/campaigns",
    component: _3f1bc76f,
    name: "animation-campaigns___en"
  }, {
    path: "/en/animation/messages",
    component: _1b9a54f3,
    name: "animation-messages___en"
  }, {
    path: "/en/animation/offers",
    component: _28ab5304,
    name: "animation-offers___en"
  }, {
    path: "/en/auth/login",
    component: _5d73a28a,
    name: "auth-login___en"
  }, {
    path: "/en/auth/resetting_password",
    component: _3e58a0fb,
    name: "auth-resetting_password___en"
  }, {
    path: "/en/error/403",
    component: _6721b647,
    name: "error-403___en"
  }, {
    path: "/en/metrics/canals",
    component: _3a613460,
    name: "metrics-canals___en"
  }, {
    path: "/en/metrics/program",
    component: _658d0ad2,
    name: "metrics-program___en"
  }, {
    path: "/en/programs/contents",
    component: _11ea575e,
    name: "programs-contents___en"
  }, {
    path: "/en/programs/management",
    component: _5c8dfc3d,
    name: "programs-management___en"
  }, {
    path: "/en/programs/rules",
    component: _f254fe60,
    name: "programs-rules___en"
  }, {
    path: "/en/sources/bars",
    component: _5976d270,
    name: "sources-bars___en"
  }, {
    path: "/en/sources/points-of-sale",
    component: _2d353bc3,
    name: "sources-points-of-sale___en"
  }, {
    path: "/en/sources/products",
    component: _263a4697,
    name: "sources-products___en"
  }, {
    path: "/en/sources/products-collection",
    component: _809704b2,
    name: "sources-products-collection___en"
  }, {
    path: "/en/users/fraud",
    component: _296f5218,
    name: "users-fraud___en"
  }, {
    path: "/en/users/management",
    component: _d4469eb4,
    name: "users-management___en"
  }, {
    path: "/en/users/segments",
    component: _bde18980,
    name: "users-segments___en"
  }, {
    path: "/en/users/transfers",
    component: _0f7bd478,
    name: "users-transfers___en"
  }, {
    path: "/metrics/canals/general",
    component: _0bbb564e,
    name: "metrics-canals-general___fr"
  }, {
    path: "/metrics/canals/onboarding_funnel",
    component: _74ff7e30,
    name: "metrics-canals-onboarding_funnel___fr"
  }, {
    path: "/metrics/canals/points_of_sale",
    component: _a9f40396,
    name: "metrics-canals-points_of_sale___fr"
  }, {
    path: "/metrics/program/general",
    component: _34714ca7,
    name: "metrics-program-general___fr"
  }, {
    path: "/metrics/program/generosity",
    component: _018e4b1d,
    name: "metrics-program-generosity___fr"
  }, {
    path: "/metrics/program/individual_behaviour",
    component: _2a995447,
    name: "metrics-program-individual_behaviour___fr"
  }, {
    path: "/metrics/program/sales",
    component: _de9503e8,
    name: "metrics-program-sales___fr"
  }, {
    path: "/metrics/program/usage",
    component: _78593fbe,
    name: "metrics-program-usage___fr"
  }, {
    path: "/sources/products/denoms",
    component: _9430599a,
    name: "sources-products-denoms___fr"
  }, {
    path: "/sources/products/denoms-v2",
    component: _540ce306,
    name: "sources-products-denoms-v2___fr"
  }, {
    path: "/sources/products/tree",
    component: _0b054969,
    name: "sources-products-tree___fr"
  }, {
    path: "/en/animation/messages/emails",
    component: _7d6cf606,
    name: "animation-messages-emails___en"
  }, {
    path: "/en/animation/messages/messages",
    component: _5f43fa8a,
    name: "animation-messages-messages___en"
  }, {
    path: "/en/animation/offers/bonus",
    component: _71b1df4b,
    name: "animation-offers-bonus___en"
  }, {
    path: "/en/animation/offers/grouped",
    component: _374c9bec,
    name: "animation-offers-grouped___en"
  }, {
    path: "/en/animation/offers/refund",
    component: _0c893708,
    name: "animation-offers-refund___en"
  }, {
    path: "/en/metrics/canals/general",
    component: _0bbb564e,
    name: "metrics-canals-general___en"
  }, {
    path: "/en/metrics/canals/onboarding_funnel",
    component: _74ff7e30,
    name: "metrics-canals-onboarding_funnel___en"
  }, {
    path: "/en/metrics/canals/points_of_sale",
    component: _a9f40396,
    name: "metrics-canals-points_of_sale___en"
  }, {
    path: "/en/metrics/program/general",
    component: _34714ca7,
    name: "metrics-program-general___en"
  }, {
    path: "/en/metrics/program/generosity",
    component: _018e4b1d,
    name: "metrics-program-generosity___en"
  }, {
    path: "/en/metrics/program/individual_behaviour",
    component: _2a995447,
    name: "metrics-program-individual_behaviour___en"
  }, {
    path: "/en/metrics/program/sales",
    component: _de9503e8,
    name: "metrics-program-sales___en"
  }, {
    path: "/en/metrics/program/usage",
    component: _78593fbe,
    name: "metrics-program-usage___en"
  }, {
    path: "/en/sources/products/denoms",
    component: _9430599a,
    name: "sources-products-denoms___en"
  }, {
    path: "/en/sources/products/denoms-v2",
    component: _540ce306,
    name: "sources-products-denoms-v2___en"
  }, {
    path: "/en/sources/products/tree",
    component: _0b054969,
    name: "sources-products-tree___en"
  }, {
    path: "/en/programs/management/:programIdentifier",
    component: _a96f1e94,
    name: "programs-management-programIdentifier___en"
  }, {
    path: "/en/users/management/:userId",
    component: _8fdf2b08,
    name: "users-management-userId___en"
  }, {
    path: "/en/programs/management/:programIdentifier/applet",
    component: _5eb709a9,
    name: "programs-management-programIdentifier-applet___en"
  }, {
    path: "/en/programs/management/:programIdentifier/assets",
    component: _29ee41e0,
    name: "programs-management-programIdentifier-assets___en"
  }, {
    path: "/en/programs/management/:programIdentifier/brands",
    component: _3256e4d0,
    name: "programs-management-programIdentifier-brands___en"
  }, {
    path: "/en/programs/management/:programIdentifier/catalog",
    component: _a4716d06,
    name: "programs-management-programIdentifier-catalog___en"
  }, {
    path: "/en/programs/management/:programIdentifier/color",
    component: _517cc6c7,
    name: "programs-management-programIdentifier-color___en"
  }, {
    path: "/en/programs/management/:programIdentifier/descriptions",
    component: _3441c17a,
    name: "programs-management-programIdentifier-descriptions___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods",
    component: _9cbdb094,
    name: "programs-management-programIdentifier-periods___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new",
    component: _085bfb4c,
    name: "programs-management-programIdentifier-periods_new___en"
  }, {
    path: "/en/programs/management/:programIdentifier/points",
    component: _38c95c80,
    name: "programs-management-programIdentifier-points___en"
  }, {
    path: "/en/programs/management/:programIdentifier/precisions",
    component: _742aed01,
    name: "programs-management-programIdentifier-precisions___en"
  }, {
    path: "/en/programs/management/:programIdentifier/puzzle",
    component: _3f5d6745,
    name: "programs-management-programIdentifier-puzzle___en"
  }, {
    path: "/en/programs/management/:programIdentifier/renewal_strategy",
    component: _1753d7b4,
    name: "programs-management-programIdentifier-renewal_strategy___en"
  }, {
    path: "/en/programs/management/:programIdentifier/stamps",
    component: _53dd7933,
    name: "programs-management-programIdentifier-stamps___en"
  }, {
    path: "/en/programs/management/:programIdentifier/visibility",
    component: _5c120444,
    name: "programs-management-programIdentifier-visibility___en"
  }, {
    path: "/en/users/management/:userId/app",
    component: _dddc49e4,
    name: "users-management-userId-app___en"
  }, {
    path: "/en/users/management/:userId/declarations",
    component: _7ab55342,
    name: "users-management-userId-declarations___en"
  }, {
    path: "/en/users/management/:userId/profile",
    component: _4327c448,
    name: "users-management-userId-profile___en"
  }, {
    path: "/en/users/management/:userId/timeline",
    component: _04f96507,
    name: "users-management-userId-timeline___en"
  }, {
    path: "/en/users/management/:userId/app/cards",
    component: _98b82dc2,
    name: "users-management-userId-app-cards___en"
  }, {
    path: "/en/users/management/:userId/app/coupons",
    component: _29fe9e6e,
    name: "users-management-userId-app-coupons___en"
  }, {
    path: "/en/users/management/:userId/app/misc",
    component: _e875b740,
    name: "users-management-userId-app-misc___en"
  }, {
    path: "/en/users/management/:userId/app/pot",
    component: _083fc411,
    name: "users-management-userId-app-pot___en"
  }, {
    path: "/en/users/management/:userId/app/timeline",
    component: _33845335,
    name: "users-management-userId-app-timeline___en"
  }, {
    path: "/en/users/management/:userId/bans/:banId?",
    component: _3b1783e5,
    name: "users-management-userId-bans-banId___en"
  }, {
    path: "/en/users/management/:userId/declarations/:declarationIdentifier",
    component: _1a7d658b,
    name: "users-management-userId-declarations-declarationIdentifier___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/acquisitions",
    component: _36cb37a2,
    name: "programs-management-programIdentifier-periods_new-periodId-acquisitions___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/explicit_mixity",
    component: _0a245d5e,
    name: "programs-management-programIdentifier-periods_new-periodId-explicit_mixity___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/glances",
    component: _c327335a,
    name: "programs-management-programIdentifier-periods_new-periodId-glances___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/in_app_operation",
    component: _126b746a,
    name: "programs-management-programIdentifier-periods_new-periodId-in_app_operation___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/mixity",
    component: _5c3d46ee,
    name: "programs-management-programIdentifier-periods_new-periodId-mixity___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/purchases_by_region",
    component: _9d069efa,
    name: "programs-management-programIdentifier-periods_new-periodId-purchases_by_region___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/refunds",
    component: _56ec150f,
    name: "programs-management-programIdentifier-periods_new-periodId-refunds___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/retailer_share",
    component: _4301d6ec,
    name: "programs-management-programIdentifier-periods_new-periodId-retailer_share___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/sales_details",
    component: _6ad1f7ba,
    name: "programs-management-programIdentifier-periods_new-periodId-sales_details___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/spendings_quantiles",
    component: _c2d70350,
    name: "programs-management-programIdentifier-periods_new-periodId-spendings_quantiles___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/uplift_reports",
    component: _739c427c,
    name: "programs-management-programIdentifier-periods_new-periodId-uplift_reports___en"
  }, {
    path: "/en/programs/management/:programIdentifier/periods_new/:periodId/widget",
    component: _45d89360,
    name: "programs-management-programIdentifier-periods_new-periodId-widget___en"
  }, {
    path: "/programs/management/:programIdentifier",
    component: _a96f1e94,
    name: "programs-management-programIdentifier___fr"
  }, {
    path: "/users/management/:userId",
    component: _8fdf2b08,
    name: "users-management-userId___fr"
  }, {
    path: "/programs/management/:programIdentifier/applet",
    component: _5eb709a9,
    name: "programs-management-programIdentifier-applet___fr"
  }, {
    path: "/programs/management/:programIdentifier/assets",
    component: _29ee41e0,
    name: "programs-management-programIdentifier-assets___fr"
  }, {
    path: "/programs/management/:programIdentifier/brands",
    component: _3256e4d0,
    name: "programs-management-programIdentifier-brands___fr"
  }, {
    path: "/programs/management/:programIdentifier/catalog",
    component: _a4716d06,
    name: "programs-management-programIdentifier-catalog___fr"
  }, {
    path: "/programs/management/:programIdentifier/color",
    component: _517cc6c7,
    name: "programs-management-programIdentifier-color___fr"
  }, {
    path: "/programs/management/:programIdentifier/descriptions",
    component: _3441c17a,
    name: "programs-management-programIdentifier-descriptions___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods",
    component: _9cbdb094,
    name: "programs-management-programIdentifier-periods___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new",
    component: _085bfb4c,
    name: "programs-management-programIdentifier-periods_new___fr"
  }, {
    path: "/programs/management/:programIdentifier/points",
    component: _38c95c80,
    name: "programs-management-programIdentifier-points___fr"
  }, {
    path: "/programs/management/:programIdentifier/precisions",
    component: _742aed01,
    name: "programs-management-programIdentifier-precisions___fr"
  }, {
    path: "/programs/management/:programIdentifier/puzzle",
    component: _3f5d6745,
    name: "programs-management-programIdentifier-puzzle___fr"
  }, {
    path: "/programs/management/:programIdentifier/renewal_strategy",
    component: _1753d7b4,
    name: "programs-management-programIdentifier-renewal_strategy___fr"
  }, {
    path: "/programs/management/:programIdentifier/stamps",
    component: _53dd7933,
    name: "programs-management-programIdentifier-stamps___fr"
  }, {
    path: "/programs/management/:programIdentifier/visibility",
    component: _5c120444,
    name: "programs-management-programIdentifier-visibility___fr"
  }, {
    path: "/users/management/:userId/app",
    component: _dddc49e4,
    name: "users-management-userId-app___fr"
  }, {
    path: "/users/management/:userId/declarations",
    component: _7ab55342,
    name: "users-management-userId-declarations___fr"
  }, {
    path: "/users/management/:userId/profile",
    component: _4327c448,
    name: "users-management-userId-profile___fr"
  }, {
    path: "/users/management/:userId/timeline",
    component: _04f96507,
    name: "users-management-userId-timeline___fr"
  }, {
    path: "/users/management/:userId/app/cards",
    component: _98b82dc2,
    name: "users-management-userId-app-cards___fr"
  }, {
    path: "/users/management/:userId/app/coupons",
    component: _29fe9e6e,
    name: "users-management-userId-app-coupons___fr"
  }, {
    path: "/users/management/:userId/app/misc",
    component: _e875b740,
    name: "users-management-userId-app-misc___fr"
  }, {
    path: "/users/management/:userId/app/pot",
    component: _083fc411,
    name: "users-management-userId-app-pot___fr"
  }, {
    path: "/users/management/:userId/app/timeline",
    component: _33845335,
    name: "users-management-userId-app-timeline___fr"
  }, {
    path: "/users/management/:userId/bans/:banId?",
    component: _3b1783e5,
    name: "users-management-userId-bans-banId___fr"
  }, {
    path: "/users/management/:userId/declarations/:declarationIdentifier",
    component: _1a7d658b,
    name: "users-management-userId-declarations-declarationIdentifier___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/acquisitions",
    component: _36cb37a2,
    name: "programs-management-programIdentifier-periods_new-periodId-acquisitions___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/explicit_mixity",
    component: _0a245d5e,
    name: "programs-management-programIdentifier-periods_new-periodId-explicit_mixity___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/glances",
    component: _c327335a,
    name: "programs-management-programIdentifier-periods_new-periodId-glances___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/in_app_operation",
    component: _126b746a,
    name: "programs-management-programIdentifier-periods_new-periodId-in_app_operation___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/mixity",
    component: _5c3d46ee,
    name: "programs-management-programIdentifier-periods_new-periodId-mixity___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/purchases_by_region",
    component: _9d069efa,
    name: "programs-management-programIdentifier-periods_new-periodId-purchases_by_region___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/refunds",
    component: _56ec150f,
    name: "programs-management-programIdentifier-periods_new-periodId-refunds___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/retailer_share",
    component: _4301d6ec,
    name: "programs-management-programIdentifier-periods_new-periodId-retailer_share___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/sales_details",
    component: _6ad1f7ba,
    name: "programs-management-programIdentifier-periods_new-periodId-sales_details___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/spendings_quantiles",
    component: _c2d70350,
    name: "programs-management-programIdentifier-periods_new-periodId-spendings_quantiles___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/uplift_reports",
    component: _739c427c,
    name: "programs-management-programIdentifier-periods_new-periodId-uplift_reports___fr"
  }, {
    path: "/programs/management/:programIdentifier/periods_new/:periodId/widget",
    component: _45d89360,
    name: "programs-management-programIdentifier-periods_new-periodId-widget___fr"
  }, {
    path: "/",
    component: _2500a0e9,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
